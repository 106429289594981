.expandableDeviceContainer {
    cursor: pointer;
    padding: 3px;
    width: 100%;
    text-align: left;
    outline: none;
}

.expandableDeviceEditInput {
    font-size: 1em;
    padding: 4px;
    padding-bottom: 2px;
    padding-left: 6px;
    width: 400px
}

.expandableDeviceCheckbox {
    width: 1.2em;
    height: 1.2em;
}

/* Add a background color to the button if it is clicked on (add the .active class with JS), and when you move the mouse over it (hover) */
/*.expanded, .collapsible:hover {
    background-color: #ccc;
}*/

/* Style the collapsible content. Note: hidden by default */
.expandableDeviceContent {
    padding: 0;
    display: none;
    overflow: hidden;
}

.expandableDeviceLeafNode {
    padding-left: 17px;
}
